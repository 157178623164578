<template>
  <default-layout>
    <portal to="sidebarContent">
      <div class="sidebar-left__content-header">
        {{ $t('app.navigation') }}
      </div>
      <div class="sidebar-left__menu">
        <ul>
          <li>
            <router-button
              routeName="MetricsOverview"
              :text="$t('metrics.overview')"
            ></router-button>
          </li>
          <li>
            <router-button
              routeName="MetricsUserPerformance"
              :text="$t('metrics.user_performance')"
            ></router-button>
          </li>
          <li>
            <router-button
              routeName="MetricsBrandUsage"
              :text="$t('metrics.brand_usage')"
            ></router-button>
          </li>
          <li>
            <router-button
              routeName="MetricsAssetsUsage"
              :text="$t('metrics.assets_usage')"
            ></router-button>
          </li>
        </ul>
      </div>
    </portal>
    <div>
      <vue-page-transition name="fade">
        <router-view></router-view>
      </vue-page-transition>
    </div>
  </default-layout>
</template>

<script>
export default {
  name: 'MetricsLayout'
}
</script>

<style scoped></style>
